.code-box .head {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border: 3px solid rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 13px;
}

.code-box .head .copy-button, .code-box .head .lang-name {
    background: none;
    border: none;
    outline: none;
    font-size: calc(10px + 13 * (var(--resize-calc)));
}

.code-box pre {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-left: 3px solid rgba(255, 255, 255, 0.2);
    border-bottom: 3px solid rgba(255, 255, 255, 0.2);
    border-right: 3px solid rgba(255, 255, 255, 0.2);
    background: rgba(0, 0, 0, 0.3);
    padding: calc(8px + 16 * (var(--resize-calc)));
    padding-bottom: calc(0px + 8 * (var(--resize-calc)));
}

.code-box code {
    padding: 0 !important;
    background: transparent;
    padding-bottom: 8px !important;
}


.code-box code::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background: rgb(35, 35, 35);
    cursor: default;

}



.code-box code::-webkit-scrollbar-thumb {
    background: rgb(69, 69, 69);
    border-radius: 10px;
}

.code-box pre .discord-ad-parent {
    display: flex;
    align-items: center;
    justify-content: end;

    white-space: wrap;
    padding-bottom: 8px;
    margin-top: 8px;

}

.code-box pre .discord-ad {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
}

.code-box pre .discord-ad p {
    transition: all 0.3s ease-in-out;
    font-size: 0;
    max-width: 0px;
}

.code-box pre .discord-ad:hover p {
    max-width: 900px;
    font-size: calc(10px + 3 * (var(--resize-calc)));
}

.code-box pre .discord-ad .ds-svg svg {
    width: 33px;
    height: 24px;
}

.code-box {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 15px;
}

@media (max-width: 1050px) {
    .code-box .head {
        padding-right: 7px;
        padding-left: 10px;
    }
}