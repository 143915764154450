.chat-page {
    /* left: 0vw;
    transition: left 0.5s ease-in-out;
    position: fixed;
    overflow-y: scroll; */
}

.chat-page {
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    padding-left: calc(20px + 52 * (var(--resize-calc)));
    padding-right: calc(20px + 52 * (var(--resize-calc)));
    position: fixed;
    left: 0;
    height: 85vh;
    width: 100vw;
}


.chat-parent .replenish {
    width: calc(160px + 66 * (var(--resize-calc)));
    height: calc(32px + 16 * (var(--resize-calc)));
    border: 3px solid rgba(255, 255, 255, 0.2);
    background: rgba(0, 0, 0, 0.3);
    font-size: calc(13px + 5 * (var(--resize-calc)));
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: calc((32px + 16 * var(--resize-calc)) + (8px + 16 * var(--resize-calc)));
    margin-top: 12px;
    border-radius: 15px;
    margin-bottom: 32px;
}


.chat-parent {
    max-width: 1128px;
    width: 100%;
    left: calc((40px + 32 * var(--resize-calc)));
}

.chat-page::-webkit-scrollbar {
    width: 12px;
    background: rgb(35, 35, 35);
}

.chat-page::-webkit-scrollbar-thumb {
    background: rgb(69, 69, 69);
    border-radius: 10px;
}

.recognize-fetching {
    white-space: nowrap;
}

@media (max-width: 1050px) {
    .chat-page {
        height: auto;
        position: unset;
        padding-left: calc((30px + 50 * var(--resize-calc)));
        padding-right: 0;
        width: auto;
        padding-bottom: 20px;
        margin-top: 72px;
    }

    .chat-page::-webkit-scrollbar {
        display: none;
    }

    .chat-page::-webkit-scrollbar-thumb {
        display: none;
    }

    .margin-block {
        margin-top: 20px;
        height: 20px;
    }


}