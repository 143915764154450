.parent {
    max-width: 1128px;
    width: calc(100vw - 48px);
    position: relative;
}

header {
    max-width: 1128px;
    width: 100%;
    height: 72px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    /* padding-left: 12px;
    padding-right: 12px; */
}


header .me {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: calc(12px + 12 * (var(--resize-calc)));
    position: relative;
}

header .me svg {
    cursor: pointer;
}

header .me .chat-svg svg {
    width: calc(24px + 18 * (var(--resize-calc)));
    height: calc(24px + 18 * (var(--resize-calc)));
}

header .me .chat-svg {
    display: flex;
    align-items: center;
    justify-content: center;
}

header .me {
    display: flex;
    align-items: center;
    justify-content: center;
}

header .me .me-svg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(24px + 16 * (var(--resize-calc)));
    height: calc(24px + 16 * (var(--resize-calc)));
}

header .me .me-svg svg {
    width: 100%;
    height: 100%;
}

.dark-bg {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2000;
    right: 0vw;
    top: 0vh;
    width: 100vw;
    height: 100vh;
}

header .me .info {
    z-index: 2001;
    position: absolute;
    top: calc(46px + 10 * (var(--resize-calc)));
    right: 0;
    background: linear-gradient(150.64deg, #8E2C9D 0%, #231835 100%);
    padding: calc(8px + 4 * (var(--resize-calc)));
    width: calc(144px + 48 * (var(--resize-calc)));
    border-radius: calc(15px + 5 * (var(--resize-calc)));
}

header .me .info .avatar--name {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 8px;
}

header .me .info .avatar--name .name {
    font-size: calc(12px + 4 * (var(--resize-calc)));
    font-weight: 400;

}

header .me .info .replenish, header .me .info .logout {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: calc(11px + 2 * (var(--resize-calc)));
    font-weight: 500;

    cursor: pointer;
}

header .me .info .replenish svg, header .me .info .logout svg {
    width: calc(6px + 2 * (var(--resize-calc)));
    height: calc(10px + 4 * (var(--resize-calc)));
}

header .me .info .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
}

header .me .info .avatar img {
    width: calc(40px + 12 * (var(--resize-calc)));
    height: calc(40px + 12 * (var(--resize-calc)));
    border-radius: 50%;
}

header .me .balance {

    font-size: calc(11px + 2 * (var(--resize-calc)));
    margin-top: 8px;
}

header .me .balance span {
    margin-left: calc(8px + 8 * (var(--resize-calc)));
}

header .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

header .logo svg {
    width: calc(28px + 22 * (var(--resize-calc)));
    height: calc(28px + 22 * (var(--resize-calc)));
}

header .logo h1 {
    font-weight: 700;
    font-size: calc(10px + 10 * (var(--resize-calc)));
    color: #FFFFFF;
    padding-left: 16px;
    white-space: nowrap;
    margin-right: 20px;
}

header .button-parent {
    display: flex;
    /* margin-right: 72px; */
    margin-right: 0px;
    justify-content: space-between;
    gap: calc(0px + 40 * (var(--resize-calc)));
    margin-left: calc(0px + 0 * (var(--resize-calc)));
}



header button {
    outline: none;
    border: none;
    background-color: transparent;
}

header button h1 {
    font-weight: 400;
    font-size: calc(12px + 6 * (var(--resize-calc)));
    color: #FFFFFF;
    white-space: nowrap;
}

.enter-button {
    width: calc(96px + 64 * (var(--resize-calc)));
    height: calc(24px + 16 * (var(--resize-calc)));
    top: calc(50% - 40px/2);
    background: linear-gradient(86.42deg, #3B2F6D 0%, #702F91 51%, #AC2EB7 100%);
    border-radius: calc(6px + 4 * (var(--resize-calc)));
    margin-left: calc(0px + 64 * (var(--resize-calc)));
}

.enter-button p {
    font-weight: 500;
    font-size: calc(12px + 8 * (var(--resize-calc)));
    color: #FFFFFF;
}

.bg-photo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-position: center;
    z-index: -100000;
    filter: brightness(0.3);
}

.pay-form {
    background: linear-gradient(150.13deg, rgba(142, 44, 157, 1), rgba(35, 24, 53, 1));
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(272px + 280 * (var(--resize-calc)));
    padding: calc(16px + 16 * (var(--resize-calc)));
    flex-direction: column;
}

.pay-form h1 {
    font-size: calc(18px + 18 * (var(--resize-calc)));
    font-weight: 500;

}

.pay-form button {
    background: linear-gradient(86.42deg, #3B2F6D 0%, #702F91 51%, #AC2EB7 100%);
    border-radius: 30px;
    width: calc(112px + 104 * (var(--resize-calc)));
    height: calc(20px + 20 * (var(--resize-calc)));
    box-shadow: -10px 8px 19.3px 0px rgba(0, 0, 0, 0.14);
    border: none;
    outline: none;
    margin-top: calc(12px + 20 * (var(--resize-calc)));
    font-size: calc(9px + 9 * (var(--resize-calc)));
    font-weight: 600;
}

.pay-form .field-parent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: calc(12px + 20 * (var(--resize-calc)));
}

.pay-form .field-parent .field {
    position: relative;
    width: 100%;
    background: linear-gradient(108.2deg, rgba(255, 255, 255, 0.3) 0.52%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%);
    max-width: 424px;
    border-radius: 30px;
}

.pay-form .field-parent p {
    font-size: calc(7px + 7 * (var(--resize-calc)));
    font-weight: 500;
    margin-bottom: calc(4px + 4 * (var(--resize-calc)));
}


.pay-form .field-parent input {
    outline: none;
    background: transparent;
    padding: calc(5px + 4 * (var(--resize-calc)));
    padding-left: calc(12px + 4 * (var(--resize-calc)));
    padding-right: calc(12px + 4 * (var(--resize-calc)));
    font-size: calc(13px + 5 * (var(--resize-calc)));
    border-radius: 30px;
    max-width: calc(224px + 200 * (var(--resize-calc)));
    width: 100%;
    border: none;
    z-index: 3001;
    border: 2px solid rgba(255, 255, 255, 0.2);
}

.pay-form .field-parent input::placeholder {
    color: rgb(255, 255, 255, 0.3);
    opacity: 1;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

body::-webkit-scrollbar {
    width: 12px;
    background: rgb(35, 35, 35);
}

body::-webkit-scrollbar-thumb {
    background: rgb(69, 69, 69);
    border-radius: 10px;
}

@media (max-width: 1050px) {
    header .button-parent {
        display: none;
    }

    header {
        /* width: calc(100vw - 24px);
        padding-left: 12px;
        padding-right: 12px; */
        display: flex;
        justify-content: space-between;
    }

    body::-webkit-scrollbar {
        display: none;
    }

    body::-webkit-scrollbar-thumb {
        display: none;
    }
}