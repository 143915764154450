.questions-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: calc(0px + 140 * (var(--resize-calc)));
    padding-top: calc(42px + 98 * (var(--resize-calc)));

    position: relative;
    margin-bottom: calc(84px + 196 * (var(--resize-calc)));
}

.circles-bg {
    position: relative;
    width: 100%;
    margin-top: calc(16px + 112 * (var(--resize-calc)));
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.questions-parent .circle-1 {
    position: absolute;
    z-index: -50;
    width: 261px;
    height: 261px;
    right: 0;
    top: -75px;
    border-radius: 50%;
    background: linear-gradient(108.2deg, rgba(134, 43, 159, 0.452) 0.52%, rgba(42, 33, 64, 0.343) 80%);
    box-shadow: -14px 11px 16.4px rgba(0, 0, 0, 0.21);
    backdrop-filter: blur(7.15px);
}

.questions-parent .circle-2 {
    z-index: -50;
    position: absolute;
    right: 100px;
    top: 128px;
    width: 161px;
    height: 161px;
    background: linear-gradient(108.2deg, rgba(93, 48, 189, 0.324) 0.52%, rgba(55, 46, 72, 0.462) 80%);
    box-shadow: 14px 11px 16.4px rgba(0, 0, 0, 0.21);
    backdrop-filter: blur(7.15px);
    border-radius: 50%;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.questions-parent .circle-3 {
    position: absolute;
    z-index: -50;
    width: 160px;
    height: 160px;
    left: 100px;
    top: 296px;
    border-radius: 50%;
    background: linear-gradient(108.2deg, rgba(134, 43, 159, 0.452) 0.52%, rgba(42, 33, 64, 0.343) 80%);
    box-shadow: -14px 11px 16.4px rgba(0, 0, 0, 0.21);
    backdrop-filter: blur(7.15px);
}

.questions-parent h1 {
    font-size: calc(20px + 28 * (var(--resize-calc)));
    font-weight: 700;
    text-align: center;
}

.questions-parent .questions {
    max-width: 744px;
}

.questions-parent .question .text {
    font-size: calc(9px + 7 * (var(--resize-calc)));
}

.questions-parent .question .visible {
    font-size: calc(10px + 14 * (var(--resize-calc)));
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc(6px + 18 * (var(--resize-calc)));
}

.questions-parent .question-parent+.question-parent {
    margin-top: calc(8px + 8 * (var(--resize-calc)));
}

.questions-parent .question-parent {
    width: 100%;
    border-radius: 20px;
    position: relative;
}

.questions-parent .question {
    background: linear-gradient(206.1deg, rgba(110, 32, 128, 0.275) 0%, rgba(87, 59, 122, 0.766) 100%);
    padding-left: calc(12px + 20 * (var(--resize-calc)));
    padding-right: calc(9px + 15 * (var(--resize-calc)));
    padding-top: calc(4px + 9 * (var(--resize-calc)));
    max-height: calc(20px + 36 * (var(--resize-calc)));
    border-radius: calc(7px + 13 * (var(--resize-calc)));
    width: 100%;
    overflow: hidden;
    transition: max-height .6s ease-in-out;
    padding-bottom: calc(6px + 26 * (var(--resize-calc)));
    box-shadow: -12px 12px 10.2px 0px rgba(0, 0, 0, 0.14);
    cursor: pointer;
}

.questions-parent .question-border {
    border-radius: calc(7px + 13 * (var(--resize-calc)));
    border: 2px solid transparent;
    background: linear-gradient(90deg, #675593 0%, rgba(132, 72, 144, 0.13) 100%) border-box;
    -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    mask-composite: exclude;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -10;
}


.questions-parent .question-parent.full .question {
    max-height: 200px;
}

.questions-parent .question-parent.full .arrow {

    transform: rotate(180deg);
}

.questions-parent .question .arrow {
    transition: transform 0.5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.questions-parent .question .arrow svg {
    width: calc(10px + 17 * (var(--resize-calc)));
    height: calc(6px + 10 * (var(--resize-calc)));
}

@media (max-width: 1200px) {
    .home-page {
        width: calc(100vw - 48px);
        padding-left: 12px;
        padding-right: 12px;
    }

    .questions-parent .circle-3, .questions-parent .circle-2, .questions-parent .circle-1 {
        display: none;
    }
}