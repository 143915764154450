.control-panel-parent {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;

}

.icons-mobile-toggler-parent {
    display: none;
    z-index: 2000;
    background: var(--body-bg);
    border-radius: 50%;
}

.icons-mobile-toggler-parent .icons-mobile-toggler-border {
    background: linear-gradient(75.89deg, rgba(0, 0, 0, 0) 0.14%, rgba(72, 72, 72, 0.63) 46.36%, #737373 100%) border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    mask-composite: exclude;
    border: 2px solid transparent;
    border-radius: 50%;
}

.icons-mobile-toggler {
    position: relative;
    background: linear-gradient(107.13deg, rgba(255, 255, 255, 0.264) -5.23%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%);
    border-radius: 50%;
    box-shadow: -12px 19px 10.6px 0px rgba(0, 0, 0, 0.25);
    width: 32px;
    height: 32px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.control-panel-parent .control-panel {
    background: linear-gradient(107.13deg, rgba(255, 255, 255, 0.264) -5.23%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%);
    position: relative;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: -12px 19px 10.6px 0px rgba(0, 0, 0, 0.25);

}

.control-panel-parent .control-panel .icons {
    padding: calc(6px + 18 * (var(--resize-calc)));
    padding-left: calc(6px + 10 * (var(--resize-calc)));
    padding-right: calc(6px + 10 * (var(--resize-calc)));
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: calc(8px + 16 * (var(--resize-calc)));

}

.control-panel .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.control-panel .icon {
    z-index: 10000;

}

.control-panel-border {
    z-index: 1000;
}

.control-panel .icon .instruction {

    position: absolute;
    right: 0px;
    top: 0;
    color: rgba(92, 89, 111, 1);
    display: flex;
    align-items: center;
    gap: 10px;
    width: 0;
    font-size: 0px;
    white-space: nowrap;
    transition: width 0.5s ease-in-out, right 0.5s ease-in-out, font-size 0.5s ease-in-out;
    z-index: -1000;
    margin-top: 10px;
}

.control-panel .icon:hover .instruction {
    width: 330px;
    right: -360px;
    font-size: 16px;
}

.control-panel .icon:hover .instruction .decor-circle-mini {
    width: 8px;
    height: 8px;
}

.control-panel .icon:hover .instruction .decor-circle-middle {
    width: 10px;
    height: 10px;
}

.control-panel .icon:hover .instruction .decor-circle-big {
    width: 12px;
    height: 12px;
}

.control-panel .icon .instruction .decor-circle {
    border-radius: 50%;
    transition: width 0.5s ease-out, height 0.5s ease-out;
    width: 0px;
    height: 0px;
}

@keyframes decor-circle-anim {
    0% {
        width: 0px;
        height: 0px;
    }

    100% {
        width: 10px;
        height: 10px;
    }
}

.control-panel .icon .instruction .decor-circle-mini {
    background: rgba(44, 40, 65, 1);

}

.control-panel .icon .instruction .decor-circle-middle {
    background: rgba(53, 49, 73, 1);

}

.control-panel .icon .instruction .decor-circle-big {
    background: rgba(60, 57, 80, 1);
    margin-right: 8px;


}

.control-panel-parent .control-panel .icon.change-fone svg {
    margin-left: -5px;
    width: calc(15px + 20 * (var(--resize-calc)));
    height: calc(17px + 18 * (var(--resize-calc)));
}

.control-panel-parent .control-panel .icon.change-fone label {
    display: flex;
    align-items: end;
}

.control-panel-parent .control-panel .icon.change-fone label svg {
    width: calc(16px + 17 * (var(--resize-calc)));
    height: calc(18px + 15 * (var(--resize-calc)));
    margin: 0;
    cursor: pointer;
}

.control-panel-parent .control-panel .icon.marker svg {
    width: calc(12px + 8 * (var(--resize-calc)));
    height: calc(18px + 14 * (var(--resize-calc)));
}

.control-panel-parent .control-panel .icon.delete svg {
    width: calc(16px + 14 * (var(--resize-calc)));
    height: calc(18px + 18 * (var(--resize-calc)));
}

.control-panel-parent .control-panel .control-panel-border {
    background: linear-gradient(75.89deg, rgba(0, 0, 0, 0) 0.14%, rgba(72, 72, 72, 0.63) 46.36%, #737373 100%) border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    mask-composite: exclude;
    border: 2px solid transparent;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;

}

.panel-bg {
    background: var(--body-bg);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

@media (max-width: 1050px) {
    .control-panel-parent .control-panel .icon.marker {
        display: none;
    }

    .control-panel .icon .instruction {
        display: none;
    }

    .icons-mobile-toggler-parent {
        display: flex;
        position: fixed;
        left: calc(4px + 12 * (var(--resize-calc)));
        bottom: calc(48px + 6 * (var(--resize-calc)));
        cursor: pointer;
    }

    .icons-mobile-toggler-parent svg {
        cursor: pointer;
    }

    .icons-mobile-toggler-border {
        cursor: pointer;
    }

    .control-panel-parent .control-panel {
        border-radius: 6px;
    }

    .control-panel-parent .control-panel .control-panel-border {

        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
        border-radius: 6px;
    }

    .control-panel-parent {
        flex-direction: column;
        left: calc(4px + 12 * (var(--resize-calc)));
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
        border-radius: 6px;
        bottom: 0px;
        width: 32px;
        height: calc(53px + 30 * (100vw - 320px) / (850 - 320));
        top: unset;
    }

    .control-panel-parent .panel-bg {
        transition: margin-bottom 0.5s ease-in-out, opacity 0.5s ease-in-out;
        margin-bottom: 54px;
        z-index: -200;
        opacity: 0;
        border-radius: 6px;
    }

    .control-panel-parent.show .panel-bg {
        margin-bottom: calc(185px + 15 * (var(--resize-calc)));
        opacity: 1;
    }

    .control-panel-parent .control-panel .icon.change-fone svg {
        margin-left: -3px;
    }

}

@media (max-width: 500px) {
    .control-panel-parent .control-panel .icon.change-fone svg {
        margin-left: -3px;
    }
}