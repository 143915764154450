.message {
    position: relative;
    margin-top: calc(32px + 32 * (var(--resize-calc)));
}

.message .text {
    padding-left: calc((40px + 32 * var(--resize-calc)));
    font-size: calc(13px + 5 * (var(--resize-calc)));
    white-space: pre-wrap;
    word-wrap: break-word;
    line-height: 1.5;
}

ol li::before {
    padding-right: 12px;
    margin-right: 12px;
    width: 12px;
}

::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 10px !important;
    text-align: start !important;
    text-align-last: start !important;
    padding-right: 12px;
    margin-right: 12px;
    width: 12px;
}

ol li p {
    margin-top: 1em;
    margin-left: 0.5em;
}

ol {
    padding-inline-start: 25px;
    white-space: wrap;
}



table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
    text-align: left;
    border: 1px solid white;
    /* Белые линии */
}

th {
    color: white;
}

.message .avatar {
    width: calc(32px + 16 * (var(--resize-calc)));
    height: calc(32px + 16 * (var(--resize-calc)));
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    z-index: -1;
    user-select: none;
    margin-top: calc(-12px - 4 * (var(--resize-calc)));
}

.message .avatar img {
    width: 100%;
    height: 100%;
}

.message:last-child {
    padding-bottom: 25px;
}

@media (max-width: 1050px) {
    .message .avatar {
        position: unset;
        margin-bottom: 8px;
    }

    .message .text {
        padding-left: 0;
    }
}