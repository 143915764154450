.skills-parent {
    padding-top: calc(42px + 98 * (var(--resize-calc)));
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: calc(42px + 98 * (var(--resize-calc)));
}

.skills-parent h5 {
    font-size: calc(20px + 28 * (var(--resize-calc)));
}

.skills-parent .description {
    display: flex;
    justify-content: center;
}

.skills-parent .skills {
    margin-top: calc(24px + 32 * (var(--resize-calc)));
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: calc(16px + 8 * (var(--resize-calc)));
}

.skills-parent .skills p {
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 400;
    font-size: calc(7px + 8 * (var(--resize-calc)));
    color: rgba(255, 255, 255, 0.4);
}

.skills-parent .skills h1 {
    margin-top: 16px;
    margin-left: 12px;
    font-weight: 500;
    font-size: calc(9px + 7 * (var(--resize-calc)));
    color: #FFFFFF;
}

.skills-parent .text {
    display: flex;
    align-items: center;
}

.skills-parent .line {
    margin-top: 9px;
    margin-bottom: 9px;
    margin-left: 2px;
    width: 40px;
    height: 5px;
    border-radius: 0px 30px 30px 0px;
}

.wh {
    width: calc(140px + 28 * (var(--resize-calc)));
    height: calc(86px + 150 * (var(--resize-calc)));
}

.border {
    position: absolute;
    border-radius: 15px;
    border: 2px solid transparent;
    -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    mask-composite: exclude;
    z-index: -1;

}

.skill-box-1 {
    margin-top: 152px;
}

.skill-box-1 .skill-border {

    background: linear-gradient(206.1deg, #a470ae 0%, rgba(255, 255, 255, 0) 86%) border-box;
}

.skills-parent .skill-box-1 .skill-1 {
    background: linear-gradient(206.1deg, #A22DB5 0%, rgba(64, 48, 112, 0) 100%);
    border-radius: 15px;
}

.skills-parent .skill-box-1 .line {
    background: rgba(124, 67, 140, 1);
}


.skill-box-2 {
    margin-top: 80px;
}

.skill-box-2 .skill-border {
    background: linear-gradient(154.1deg, #736395 0%, rgba(255, 255, 255, 0) 86%) border-box;
}

.skills-parent .skill-box-2 .skill-2 {
    background: linear-gradient(153.9deg, #413071 0%, rgba(24, 20, 47, 0) 100%);
    border-radius: 15px;
}

.skills-parent .skill-box-2 .line {
    background: #614E85;
}



.skill-box-3 .skill-border {
    background: linear-gradient(150.1deg, #a470ae 0%, rgba(255, 255, 255, 0) 86%) border-box;
}

.skills-parent .skill-box-3 .skill-3 {
    background: linear-gradient(153.9deg, #A62FB3 0%, rgba(65, 48, 113, 0) 100%);
    border-radius: 15px;
}

.skills-parent .skill-box-3 .line {
    background: #9B55A7;
}



.skill-box-4 {
    margin-top: 88px;
}

.skill-box-4 .skill-border {
    background: linear-gradient(200.1deg, #736395 0%, rgba(255, 255, 255, 0) 86%) border-box;
}

.skills-parent .skill-box-4 .skill-4 {
    background: linear-gradient(200.9deg, #413071 0%, rgba(24, 20, 47, 0) 100%);
    border-radius: 15px;
}

.skills-parent .skill-box-4 .line {
    background: #4E3C6F;
}



.skill-box-5 {
    margin-top: 176px;
}

.skill-box-5 .skill-border {
    background: linear-gradient(206.1deg, #a470ae 0%, rgba(255, 255, 255, 0) 86%) border-box;
}

.skills-parent .skill-box-5 .skill-5 {
    background: linear-gradient(206.1deg, #A22DB5 0%, rgba(64, 48, 112, 0) 100%);
    border-radius: 15px;
}

.skills-parent .skill-box-5 .line {
    background: rgba(124, 67, 140, 1);
}



.skill-box-6 {
    margin-top: 72px;
}

.skill-box-6 .skill-border {
    background: linear-gradient(154.1deg, #736395 0%, rgba(255, 255, 255, 0) 86%) border-box;
}

.skills-parent .skill-box-6 .skill-6 {
    background: linear-gradient(153.9deg, #413071 0%, rgba(24, 20, 47, 0) 100%);
    border-radius: 15px;
}

.skills-parent .skill-box-6 .line {
    background: #614E85;
}


@media (max-width: 1200px) {
    .skills-parent .skills {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
    }

    .skill-box {
        margin: 0;
    }

    .wh {
        margin: 0;
    }
}

@media (max-width: 600px) {

    .skill-box .line, .skill-box p {
        /* display: none; */
    }

    .skill-box .text h1 {
        font-size: 9px;
        margin-left: 6px;
        margin-top: 6px;
        margin-right: 6px;
    }

    .skills-parent .skill-box .wh {
        justify-content: start;
        padding: 0;
        border-radius: 7px;
    }

    .skills-parent .border {
        border-radius: 7px;
    }
}

@media (max-width: 500px) {
    .skill-box .line, .skill-box p {
        display: none;
    }

    .wh {
        width: 110px;
        height: 45px;
    }
}

@media (max-width: 400px) {
    .skill-box .line, .skill-box p {
        display: none;
    }

    .wh {
        width: 80px;
        height: 45px;
    }
}