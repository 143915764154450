* {
    /* padding: 0; */
    margin: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
    /* Убирает специфическое стилизование для iOS */
    -moz-appearance: none;
    appearance: none;
}



:root {
    --main-font-family: "Inter", sans-serif;
    --resize-calc: calc((100vw - 320px) / (1920 - 320));
    --body-bg: rgba(24, 20, 47, 1);
}

body {
    width: 100%;
    background-color: var(--body-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--main-font-family);
    color: white;
    margin: 0;
}

textarea, input, button, a {
    color: white;
    font-family: var(--main-font-family);
}

img {
    object-fit: cover;
}

button {
    cursor: pointer;
}

h5 {
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    color: #FFFFFF;
}

.description p {
    margin-top: calc(0px + 16 * (var(--resize-calc)));
    width: 70%;
    font-weight: 400;
    font-size: calc(9px + 7 * (var(--resize-calc)));
    line-height: 2;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 900px) {
    .description p {
        line-height: 1.5;
    }
}

@media (max-width: 650px) {
    .description p {
        width: 100%;
        line-height: 1.5;
    }
}