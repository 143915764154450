.tariffs-parent {
    padding-top: calc(42px + 98 * (var(--resize-calc)));
    display: flex;
    flex-direction: column;
    align-items: center;

}

.tariffs-parent h5 {
    font-size: calc(20px + 28 * (var(--resize-calc)));
}

.tariffs-parent .description {
    display: flex;
    justify-content: center;

}

.tariffs-parent .description p {
    font-size: calc(9px + 7 * (var(--resize-calc)));
}

.tariff-box-parent {
    margin-top: calc(27px + 61 * (var(--resize-calc)));
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tariff-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: calc(152px + 256 * (var(--resize-calc)));
}

.tariff-border, .blur {
    width: 100%;
    height: 100%;
}

.tariff-border {
    position: absolute;
    border-radius: calc(15px + 25 * (var(--resize-calc)));
    border-color: transparent;
    border-width: calc(2px + 2 * (var(--resize-calc)));
    border-style: solid;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.4) 97%) border-box;
    -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    mask-composite: exclude;
    filter: drop-shadow(-12px 19px 10.6px rgba(0, 0, 0, 0.25));
    z-index: -1;
}

.blur {
    position: absolute;
    z-index: -10;
    border-radius: 40px;
    backdrop-filter: blur(7.15px);

}

.tariff {
    background: linear-gradient(108.2deg, rgba(255, 255, 255, 0.3) 0.52%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%);
    border-radius: calc(15px + 25 * (var(--resize-calc)));
}

.pluses {
    padding: calc(12px + 28 * (var(--resize-calc)));
    padding-bottom: calc(24px + 36 * (var(--resize-calc)));
}

.plus:last-child {
    padding-bottom: 0px;
}

.plus {
    display: flex;
    padding-bottom: calc(9px + 17 * (var(--resize-calc)));
}

.plus p {
    font-size: calc(8px + 12 * (var(--resize-calc)));
    color: #fff;
}

.tariffs-parent .marker {
    margin-top: 4px;
    margin-right: calc(3px + 7 * (var(--resize-calc)));
    width: calc(6px + 10 * (var(--resize-calc)));
    height: calc(6px + 10 * (var(--resize-calc)));
    background: rgba(255, 255, 255, 0.24);
    border-radius: 50%;
}

.tariff-box .circle-1 {
    z-index: -100;
    position: absolute;
    /* left: calc(40px + 86 * (var(--resize-calc))); */
    left: 0;
    left: -250px;
    left: calc(-70px - 180 * (var(--resize-calc)));
    width: calc(120px + 225 * (var(--resize-calc)));
    height: calc(120px + 225 * (var(--resize-calc)));
    background: linear-gradient(108.2deg, rgba(211, 94, 229, 0.31) 0.52%, rgba(42, 33, 64, 0.188) 85%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 14px 11px 16.4px rgba(0, 0, 0, 0.21);
    /*x,y,blur,радиус,цвет*/
    backdrop-filter: blur(7.15px);
    border-radius: 50%;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.tariff-box .circle-2 {
    z-index: -50;
    position: absolute;
    left: -238px;
    left: calc(-70px - 180 * (var(--resize-calc)));
    /* left: 0; */
    top: 250px;
    top: calc(100px + 170 * (var(--resize-calc)));
    width: calc(56px + 105 * (var(--resize-calc)));
    height: calc(56px + 105 * (var(--resize-calc)));
    background: linear-gradient(108.2deg, rgba(93, 48, 189, 0.324) 0.52%, rgba(55, 46, 72, 0.462) 80%);
    box-shadow: 14px 11px 16.4px rgba(0, 0, 0, 0.21);
    backdrop-filter: blur(7.15px);
    border-radius: 50%;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.tariff-box .circle-3 {
    position: absolute;
    width: calc(40px + 104 * (var(--resize-calc)));
    height: calc(40px + 104 * (var(--resize-calc)));
    /* right: 262px; */
    right: -64px;
    right: calc(-16px - 62 * (var(--resize-calc)));
    top: -56px;
    top: calc(-16px - 54 * (var(--resize-calc)));
    border-radius: 50%;
    background: linear-gradient(108.2deg, rgba(84, 37, 183, 0.396) 0.52%, rgba(55, 46, 72, 0.462) 80%);
    box-shadow: -14px 11px 16.4px rgba(0, 0, 0, 0.21);
    backdrop-filter: blur(7.15px);
}

.circle-4 {
    position: absolute;
    z-index: -50;
    width: calc(88px + 173 * (var(--resize-calc)));
    height: calc(88px + 173 * (var(--resize-calc)));
    /* right: 145px; */
    right: calc(-50px - 140 * (var(--resize-calc)));
    bottom: 10px;
    border-radius: 50%;
    background: linear-gradient(108.2deg, rgba(134, 43, 159, 0.452) 0.52%, rgba(42, 33, 64, 0.343) 80%);
    box-shadow: -14px 11px 16.4px rgba(0, 0, 0, 0.21);
    backdrop-filter: blur(7.15px);
    /* Note: backdrop-filter has minimal browser support */

}

.button-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: calc(8px + 16 * (var(--resize-calc)));
}

.button-box p {
    font-weight: 400;
    font-size: calc(8px + 12 * (var(--resize-calc)));
    color: #FFFFFF;
}

.test-button-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    cursor: pointer;
}

.test-button p {
    font-weight: 400;
    font-size: calc(12px + 20 * (var(--resize-calc)));
    color: rgba(255, 255, 255, 0.66);
}

.test-button button {

    width: calc(136px + 224 * (var(--resize-calc)));
    height: calc(26px + 43 * (var(--resize-calc)));
    background: linear-gradient(86.42deg, rgba(59, 47, 109, 0.7) 0%, rgba(112, 47, 145, 0.7) 51%, rgba(172, 46, 183, 0.7) 100%);
    border-radius: 24px;
    border: none;
}

.test-button-border {
    border-radius: 24px;
    position: absolute;
    width: calc(136px + 224 * (var(--resize-calc)));
    height: calc(26px + 43 * (var(--resize-calc)));
    background: linear-gradient(-135deg, rgba(255, 255, 255, 0.5) 15%, rgba(0, 0, 0, 0) 30.5%, rgba(0, 0, 0, 0) 55.5%, rgb(255, 255, 255, 0.5) 100%);
    border: 2px solid transparent;
    -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    mask-composite: exclude;
    z-index: -1;
}