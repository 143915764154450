.chat-input-box-parent {
    position: fixed;
    bottom: 0;

    width: 100%;
    max-width: 1128px;
    display: flex;
    justify-content: center;
}

.chat-input-box {
    display: flex;
    justify-content: center;
    margin-bottom: 28px;
    z-index: 5000;
    border-radius: 30px;
    background: linear-gradient(108.2deg, rgba(24, 20, 47, 1), rgba(24, 20, 47, 0));
}

.chat-input-parent {
    position: relative;
    width: 100%;
    background: linear-gradient(108.2deg, rgba(255, 255, 255, 0.3) 0.52%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%);
    border-radius: 30px;
}

.chat-input-border {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(90.14deg, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 51.5%, rgba(255, 255, 255, 0.1) 100%);
    -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    mask-composite: exclude;
    border-width: calc(3px + 1 * (var(--resize-calc)));
    border-color: transparent;
    border-style: solid;
    border-radius: 30px;
}


.chat-input textarea {
    width: 100%;
    max-height: 240px;
    background: transparent;
    resize: none;
    outline: none;
    border: none;
    font-size: 18px;
    font-weight: 400;
    transition: height 2.5s ease-in-out;
}

.chat-input {
    position: relative;
    display: flex;
    align-items: end;
    padding: 12px;
    padding-left: calc(8px + 12 * (var(--resize-calc)));
    padding-right: calc(8px + 12 * (var(--resize-calc)));
    padding-top: calc(7px + 5 * (var(--resize-calc)));
    padding-bottom: calc(7px + 5 * (var(--resize-calc)));
    padding-bottom: 0;
    padding-top: 0;
    gap: calc(7px + 15 * (var(--resize-calc)));
    min-height: calc(32px + 16 * (var(--resize-calc)));
    padding-bottom: calc(2px + 7 * (var(--resize-calc)));
    padding-top: calc(2px + 7 * (var(--resize-calc)));

}

.textarea-parent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 30px;
    /* margin-left: calc(34px + 26 * (var(--resize-calc))); */
    /* margin-right: calc(30px + 30 * (var(--resize-calc))); */
}

textarea::-webkit-scrollbar {
    width: 12px;
    background: rgb(35, 35, 35);
}

textarea::-webkit-scrollbar-thumb {
    background: rgb(69, 69, 69);
    border-radius: 10px;
}

.chat-input .send-img {
    width: calc(24px + 5 * (var(--resize-calc)));
    height: calc(24px + 5 * (var(--resize-calc)));
    /* position: absolute; */
    /* bottom: calc(10px + 0 * (var(--resize-calc))); */
    padding-bottom: 3px;

}

.chat-input .send-img svg {
    width: 100%;
    height: 100%;
    cursor: pointer;
    stroke: #2A263F;
}

.chat-input .send-message {
    width: calc(23px + 0 * (var(--resize-calc)));
    height: calc(25px + 1 * (var(--resize-calc)));
    /* position: absolute; */
    /* right: calc(8px + 12 * (var(--resize-calc))); */
    /* bottom: calc(10px + 0 * (var(--resize-calc))); */
    padding-bottom: 3px;
}

.chat-input .send-message svg {
    width: 100%;
    height: 100%;
    cursor: pointer;
    stroke: #706E7C;
}

.chat-input textarea::placeholder {
    color: rgb(255, 255, 255, 0.3);
    opacity: 1;
}

.chat-input textarea::-moz-placeholder {
    color: rgb(255, 255, 255, 0.3);
    opacity: 1;
}

.chat-input textarea:-ms-input-placeholder {
    color: rgb(255, 255, 255, 0.3);
    opacity: 1;
}

.chat-input textarea::-ms-input-placeholder {
    color: rgb(255, 255, 255, 0.3);
    opacity: 1;
}

@media (max-width: 1050px) {

    .chat-input-box-parent {
        left: 0;
    }

    .chat-input .send-img {
        width: calc(28px + 5 * (var(--resize-calc)));
        height: calc(28px + 5 * (var(--resize-calc)));
        margin-bottom: calc(0px + 1 * (var(--resize-calc)));
    }

    .chat-input .send-message {
        width: calc(23px + 0 * (var(--resize-calc)));
        height: calc(25px + 1 * (var(--resize-calc)));
        margin-bottom: calc(1px + 2 * (var(--resize-calc)));
    }

    .chat-input-box {
        background: var(--body-bg);
        width: 100vw;
    }

    .chat-input-box {
        margin-bottom: 0;
    }

    .chat-input-parent {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 0px;
        width: calc(100vw);
    }

    .chat-input-box .textarea-parent {
        background: linear-gradient(108.2deg, rgba(255, 255, 255, 0.102) 0.52%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%);
        border: 2px solid rgba(81, 78, 94, 1);
        padding: 0px;
        padding-left: 9px;
        padding-right: 9px;
        border-radius: 15px;
    }

    .chat-input-box .textarea-parent textarea {
        font-size: 16px;
    }

    .chat-input-box .chat-input {
        padding: 3px;
        padding-left: calc(8px + 12 * (var(--resize-calc)));
        padding-right: calc(8px + 12 * (var(--resize-calc)));
    }

    .chat-input .send-img svg {
        stroke: white;
    }

    .chat-input .send-message svg {
        stroke: white;
    }

    .chat-input-border {
        display: none;
    }

    .chat-input-box {
        border-radius: 0px;
    }
}