.flip-clock {
    padding-top: calc(80px + 200 * (var(--resize-calc)));
}

.flip-clock h1 {
    font-size: calc(20px + 28 * (var(--resize-calc)));
    margin-bottom: calc(24px + 104 * (var(--resize-calc)));
    text-align: center;
}

.flip-clock .num {
    transform: rotateX(-180deg);
    height: calc(64px + 216 * (var(--resize-calc)));
    border-radius: calc(5px + 20 * (var(--resize-calc)));
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    background: linear-gradient(rgba(143, 79, 173, 0.63), rgba(73, 59, 122, 0.766));
    z-index: 30000;
}



.flip-clock .num-parent.animating .num {
    animation: flip 1.5s forwards;
}

.flip-clock .num-parent.animating .num-border {
    animation: flip-border 1.5s forwards;
    /* z-index: 3000; */
}

.flip-clock .num-parent {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    position: relative;
}

.flip-clock .num-border {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: calc(5px + 20 * (var(--resize-calc)));
    border: 3px solid transparent;
    background: linear-gradient(180deg, #8f81c1 0%, #8E4B9F 100%) border-box;
    -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    mask-composite: exclude;
    cursor: default;
    z-index: -1;
}


.flip-clock .anim-num.num p {
    transform: rotateX(180deg);
    text-align: center;

    font-size: calc(40px + 200 * (var(--resize-calc)));
}

.flip-clock .num-parent.animating p {
    animation: flip-int 1s forwards;
    transform: rotateX(90deg);
}

.flip-clock .nums {
    position: relative;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: calc(6px + 18 * (var(--resize-calc)));
}

.flip-clock .nums .line {
    height: calc(2px + 6 * (var(--resize-calc)));
    background: rgba(72, 15, 97, 1);
    left: 0;
    top: calc(50% - (1px + 3 * (var(--resize-calc))));
    width: 100%;
    position: absolute;
    z-index: 2000;
}

.flip-clock .bg-blur {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flip-clock .bg-blur .blur {
    filter: blur(60px);
    width: 100%;
    height: 100%;
    background: rgba(179, 0, 208, 0.31);

}


@keyframes flip-int {
    0% {
        transform: rotateX(180deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}

@keyframes flip-border {
    0% {
        transform: rotateX(-180deg);
        background: linear-gradient(180deg, #8E4B9F 100%, #8f81c1 0%) border-box;
    }

    100% {
        transform: rotateX(0deg);
        background: linear-gradient(180deg, #8f81c1 0%, #8E4B9F 100%) border-box;

    }
}

@keyframes flip {
    0% {
        transform: rotateX(-180deg);
        background: linear-gradient(rgba(143, 79, 173, 0.63), rgba(73, 59, 122, 0.766));


    }

    50% {}

    100% {
        transform: rotateX(0deg);
        background: linear-gradient(rgba(73, 59, 122, 0.766), rgba(143, 79, 173, 0.63));
    }
}

/* .flip-clock {
    padding-left: calc(0px + 186 * (var(--resize-calc)));
    padding-right: calc(0px + 186 * (var(--resize-calc)));
} */


@media (max-width: 1450px) {
    .flip-clock {
        padding-left: calc(0px + 126 * (var(--resize-calc)));
        padding-right: calc(0px + 126 * (var(--resize-calc)));
    }

    .flip-clock .num {
        height: calc(64px + 286 * (var(--resize-calc)));
        /* height: 300px; */
    }
}

@media (max-width: 750px) {
    /* .flip-clock {
        padding-left: 0px;
        padding-right: 0px;
    } */

    .flip-clock .num {
        /* height: calc(64px + 216 * (var(--resize-calc))); */
    }

    .flip-clock .anim-num.num p {
        /* font-size: calc(50px + 190 * (var(--resize-calc))); */
    }
}