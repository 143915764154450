.main-parent {
    padding-top: calc(0px + 160 * (var(--resize-calc)));
    display: flex;
    flex-direction: column;
    padding-bottom: calc(0px + 140 * (var(--resize-calc)));
}

.text-box {
    display: flex;
}

.main-parent .text-box h6:last-child {
    margin-left: calc(12px + 12 * (var(--resize-calc)));
}

.main-parent h6 {
    font-weight: 700;
    font-size: calc(24px + 40 * (var(--resize-calc)));

    color: #FFFFFF;
    margin-right: calc(12px + 12 * (var(--resize-calc)));
}

.main-parent p {
    font-weight: 700;
    font-size: calc(24px + 40 * (var(--resize-calc)));
    white-space: nowrap;
    color: rgba(167, 47, 179, 1);
}

.main-parent .button-registration button {
    width: calc(223px + 329 * (var(--resize-calc)));
    height: calc(24px + 37 * (var(--resize-calc)));
    background: linear-gradient(86.42deg, #3B2F6D 0%, #702F91 51%, #AC2EB7 100%);
    border-radius: calc(12px + 18 * (var(--resize-calc)));
    border: none;
    filter: drop-shadow(-7px 8px 21px rgba(0, 0, 0, 0.26));
    margin-top: calc(24px + 40 * (var(--resize-calc)));
    margin-bottom: calc(4px + 6 * (var(--resize-calc)));
}

.main-parent .button-registration {
    display: flex;

}

.main-parent .button-registration-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-parent .button-registration button p {
    font-weight: 600;
    font-size: calc(13px + 23 * (var(--resize-calc)));

    color: #FFFFFF;
}

.main-parent .button-registration p {
    font-weight: 400;
    font-size: calc(7px + 9 * (var(--resize-calc)));

    color: rgba(255, 255, 255, 0.35);
}

.regist h1 {
    font-weight: 700;
    font-size: calc(20px + 20 * (var(--resize-calc)));
    margin-bottom: calc(16px + 8 * (var(--resize-calc)));
    text-align: center;
}

.regist .icons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.regist .icons svg {
    width: calc(56px + 40 * (var(--resize-calc)));
    height: calc(56px + 40 * (var(--resize-calc)));
    cursor: pointer;
}

.regist {
    background: rgba(24, 20, 47, 1);
    padding: calc(16px + 8 * (var(--resize-calc)));
    padding-left: calc(36px + 60 * (var(--resize-calc)));
    padding-right: calc(36px + 60 * (var(--resize-calc)));
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: calc(10px + 5 * (var(--resize-calc)));
}

.regist .user-confirmation {
    display: flex;
    gap: 8px;
    margin-top: calc(16px + 8 * (var(--resize-calc)));
    font-size: calc(12px + 4 * (var(--resize-calc)));
}

input[type="checkbox"] {
    appearance: auto;
}

footer {
    width: 100%;
    border-top: 2px solid rgb(255, 255, 255, 0.3);
    padding-top: calc(6px + 18 * (var(--resize-calc)));
    padding-bottom: calc(6px + 18 * (var(--resize-calc)));
    padding-left: calc(12px + 4 * (var(--resize-calc)));
    padding-right: calc(12px + 4 * (var(--resize-calc)));
    display: flex;
    align-items: center;
    justify-content: space-between;

}

footer .social {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(8px + 8 * (var(--resize-calc)));
}

footer .social a {
    width: calc(24px + 16 * (var(--resize-calc)));
    height: calc(24px + 16 * (var(--resize-calc)));
    display: flex;
    align-items: center;
    justify-content: center;
}

footer .social a svg {
    width: 100%;
    height: 100%;
}

footer .social .email {
    margin-left: 8px;
    font-weight: 400;
    font-size: calc(8px + 7 * (var(--resize-calc)));
}

footer .company {
    font-weight: 400;
    font-size: calc(8px + 8 * (var(--resize-calc)));
    line-height: 1.5;
}

footer a {
    text-decoration: none;
}

@media (max-width: 1200px) {
    .home-page {
        width: calc(100vw - (32px + 16 * (var(--resize-calc))));
        padding-left: 12px;
        padding-right: 12px;
    }
}

@media (max-width: 500px) {
    footer {
        width: 100vw;
        margin-left: -24px;
    }
}